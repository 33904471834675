<template>
  <div>
    <MainNavigation />
    <router-view />
    <AppFooter />
    <BackToTop />
  </div>
</template>

<script>
import MainNavigation from './components/Partials/MainNavigation.vue'
import AppFooter from './components/Partials/AppFooter.vue'
import BackToTop from './components/Partials/BackToTop.vue'

export default {
  components: {
    MainNavigation,
    AppFooter,
    BackToTop
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
}

body {
  font-family: "Merriweather", serif;
  color: #444444;
}

a {
  text-decoration: none;
  color: $mainLightBlue;
}

a:hover {
  color: #80c792;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f8fcf9;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: $mainLightBlue;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

/*--------------------------------------------------------------
# OurTeam
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(34, 34, 34, 0.07);
  padding: 30px 20px;
  background: #fff;
}

.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #919191;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: #5cb874;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}
</style>
