<template>
  <!-- ======= Featured OurServices Section ======= -->
  <section id="featured-services" class="featured-services section-bg">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-stack"></i></div>
            <h3 class="title">Quality</h3>
            <p class="description">
              Experience a seamless financial planning process with my expertise
              at Crius Financial Services Corp. Stay ahead with personalized
              financial solutions tailored to your needs, from life insurance to
              critical illness coverage. I work with qualified professionals to
              ensure your financial security. Whether you're planning for
              retirement, protecting your family, or investing in your future, I
              am here to provide comprehensive and reliable solutions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-hand-thumbs-up-fill"></i></div>
            <h4 class="title">Professional</h4>
            <p class="description">
              With a strong track record, I specialize in delivering
              professional and personalized financial services. My commitment is
              to make your financial journey as smooth and stress-free as
              possible. Whether you're securing life insurance, planning for
              retirement, or investing in your future, I offer expert guidance
              and comprehensive solutions. Contact me for a fulfilling financial
              planning experience—your success is my success.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-briefcase-fill"></i></div>
            <h4 class="title">Expertise and Experience</h4>
            <p class="description">
              With years of proven success, I bring extensive expertise to every
              client relationship. Get a thorough evaluation of your financial
              needs and stay informed with my tailored advice and solutions. I
              work closely with qualified professionals to provide top-tier
              financial services, ensuring your peace of mind. Whether you're
              buying insurance, investing, or planning your estate, I offer
              reliable and comprehensive support.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured OurServices Section -->
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Featured OurServices
--------------------------------------------------------------*/
.featured-services {
  padding: 20px 0;
}

.featured-services .icon-box {
  padding: 20px;
}

.featured-services .icon-box-bg {
  background-image: linear-gradient(
    0deg,
    #222222 0%,
    #2f2f2f 50%,
    #222222 100%
  );
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  color: $mainLightBlue;
  font-size: 42px;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #222222;
  transition: 0.3s;
}

.featured-services .icon-box:hover .title a {
  color: $mainLightBlue;
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}
</style>
