export const CORPORATE_DATA = {
  facebookLink: '#',
  instagrammLink: '#',
  phoneNumbers: {
    eugene: '604-366-6636'
  },
  address: '',
  email: 'joanneliu@crius.ca',
  supportEmailData: {
    supportEmailSubject: 'Support Request: Assistance Needed with Insurance Services',
    supportEmailBody: 'Hi Joanne,'
  },
  companyTitle: 'Crius Financial Services Corp.',
  copyrightTitle: 'Crius Financial Services Corp.',
  locationPin: '',
  locationPinShort: '',
  formSendUrl: 'https://codebridge-email-sender.herokuapp.com/email-sender',
  imgBaseUrl: 'https://objectstorage.ca-toronto-1.oraclecloud.com/p/jE02KCLipZSKqdPxfle5ABFxoo3kkhQaCZySIYDoeGA5vq21F6brBA1inVn0dds4/n/yzfveo9zjrvn/b/bucket-20230109-1321/o/codebridge/',
  googleMapsPin: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1302.7845989412242!2d-123.0009391303511!3d49.22769667402626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486765906294161%3A0x4f0622a2cca995a7!2sCrius%20Financial%20Services%20Corp!5e0!3m2!1sen!2sca!4v1723601347619!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
  designedBy: 'SoftMedia',
  designedByUrl: 'https://www.facebook.com/profile.php?id=61551214474657'
}
