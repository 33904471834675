<template>
  <div class="home">
    <WelcomeSlider />
    <AboutUs />
    <OurServices />
    <WhyUs />
    <OurClients />
    <AdditionalServices />
    <CallToAction />
    <!-- <ProjectPortfolio /> -->
    <!-- <OurTeam /> -->
    <ContactUs />
  </div>
</template>

<script>
import AboutUs from '../components/Partials/AboutUs.vue'
import OurServices from '../components/Partials/OurServices.vue'
import WelcomeSlider from '../components/Partials/WelcomeSlider.vue'
import WhyUs from '../components/Partials/WhyUs.vue'
import OurClients from '../components/Partials/OurClients.vue'
import AdditionalServices from '../components/Partials/AdditionalServices.vue'
import CallToAction from '../components/Partials/CallToAction.vue'
// import ProjectPortfolio from "../components/Partials/ProjectPortfolio.vue";
// import OurTeam from "../components/Partials/OurTeam.vue";
import ContactUs from '../components/Partials/ContactUs.vue'

export default {
  name: 'HomePage',
  components: {
    AboutUs,
    OurServices,
    WelcomeSlider,
    WhyUs,
    OurClients,
    AdditionalServices,
    CallToAction,
    // ProjectPortfolio,
    // OurTeam,
    ContactUs
  }
}
</script>
