import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomePage from '../views/HomePage.vue'
import NotFoundPage from '../components/NotFoundPage.vue'

const routes = [{
  path: '/',
  name: 'HomePage',
  component: HomePage
},
{
  path: '/portfolio-details',
  name: 'PortfolioDetails',
  component: () => import(/* webpackChunkName: "about" */ '../components/PortfolioDetails.vue')
},
{
  path: '/:catchAll(.*)',
  name: NotFoundPage,
  component: NotFoundPage
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
