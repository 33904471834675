<template>
  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">
      <div class="row no-gutters ms-3 me-3 ms-md-0 me-md-0">
        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(1) }}</span>
          <h4>Proven Expertise</h4>
          <p>
            As a seasoned insurance agent with years of experience, I bring a
            wealth of knowledge to ensure your financial plans are handled with
            precision and professionalism. My commitment to excellence in
            financial services is reflected in the personalized care I provide
            to each client.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(2) }}</span>
          <h4>Comprehensive Financial Insight</h4>
          <p>
            Benefit from my extensive experience and deep understanding of the
            financial landscape. Receive a FREE Financial Needs Assessment,
            offering you a clear view of your insurance coverage and investment
            options tailored to your unique needs.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(3) }}</span>
          <h4>Exclusive Access to Tailored Insurance Solutions</h4>
          <p>
            Stay ahead with my exclusive offerings in life, critical illness,
            and disability insurance. I have the resources to find the best
            coverage that suits your specific requirements, giving you peace of
            mind and security.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(4) }}</span>
          <h4>Financial Expertise</h4>
          <p>
            Navigate your financial journey seamlessly with the support of
            qualified professionals. I understand the complexities of insurance
            and investment planning and am here to guide you in making informed
            decisions for your future.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(5) }}</span>
          <h4>Strategic Planning</h4>
          <p>
            Rely on my proven expertise to craft strategic financial plans that
            meet your goals. I work diligently to ensure that your financial
            strategies are aligned with your long-term objectives, securing the
            best outcomes for you and your family.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(6) }}</span>
          <h4>Unparalleled Support and Service</h4>
          <p>
            Your financial well-being is my priority. I go beyond the standard
            level of service, offering continuous support and ensuring that your
            financial plans are effectively implemented and monitored for
            success.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Us Section -->
</template>

<script>
export default {
  methods: {
    intToRoman (num) {
      if (num < 1 || num > 3999) {
        return 'Invalid number'
      }

      const romanNums = [
        'M',
        'CM',
        'D',
        'CD',
        'C',
        'XC',
        'L',
        'XL',
        'X',
        'IX',
        'V',
        'IV',
        'I'
      ]
      const integerNums = [
        1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1
      ]

      let romanStr = ''

      for (let i = 0; i < integerNums.length; i++) {
        while (num >= integerNums[i]) {
          romanStr += romanNums[i]
          num -= integerNums[i]
        }
      }

      return romanStr
    }
  }
}
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 0;
}

.why-us .row {
  overflow: hidden;
}

.why-us .content-item {
  padding: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: -1px;
}

.why-us .content-item span {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: $mainLightBlue;
}

.why-us .content-item h4 {
  font-size: 26px;
  font-weight: 300;
  padding: 0;
  margin: 20px 0;
}

.why-us .content-item p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .why-us .content-item {
    padding: 40px 0;
  }
}
</style>
