<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>About</h2>
        <p>My experience and academic education are guarantees of success</p>
      </div>

      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2">
          <img src="../../assets/img/about-us-2.jpg" class="w-100" alt="image" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <!-- <h3>Welcome</h3> -->
          <p class="mt-3">
            I am Joanne Liu, a passionate Sales Representative at Crius
            Financial Services Corp., dedicated to helping clients secure their
            financial futures. With extensive experience in life insurance,
            critical illness coverage, and investment products, I take pride in
            offering personalized solutions tailored to each client's unique
            needs and goals. My commitment to integrity, professionalism, and
            client well-being drives me to build strong, lasting relationships
            with those I serve. Whether you're planning for retirement or
            seeking to protect your family's future, I am here to guide you with
            expert advice and unwavering support.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->
</template>

<script>
import { CORPORATE_DATA } from '../../constants'

export default {
  data () {
    return {
      corporateData: CORPORATE_DATA
    }
  }
}
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}

.about .content p:last-child {
  margin-bottom: 0;
}
</style>
